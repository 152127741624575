<template>
    <e-iframe-page :loading="loading" ref="iframePage" :title="'Create Page|Créer une page'|tr" body-class="bg-white text-left" body-width="600">
        <template v-if="newPage">
<!--            <tab-header v-model="newPage.name" :icon="getPublicIcon('file-bookmark')">-->
<!--                <template #input>-->
<!--                    <e-translation-input label="Page Name|Nom de la page" v-model="newPage.name">-->
<!--                        <template #form-help>-->
<!--                            <div class="form-help mt-2" v-tr>-->
<!--                                The page name will be used for navigation links|-->
<!--                                Le nom de la page sera utilisé pour les liens de navigation-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </e-translation-input>-->
<!--                </template>-->
<!--            </tab-header>-->

            <!--            <b-form-group :disabled="!dealerIds || dealerIds.length <= 1">-->
            <!--                <label v-tr>Venue (required)|Compte (requis)</label>-->
            <!--                <vue-multiselect v-model="newPage.dealerid" placeholder="Choose a dealer" :allowEmpty="false"-->
            <!--                                 :options="dealerIds">-->
            <!--                </vue-multiselect>-->
            <!--            </b-form-group>-->

<!--            <b-card class="simple mt-3">-->
<!--                <template #header>-->
<!--                    <h4>-->
<!--                        <i class="fas fa-cog fa-main-icon"></i>-->
<!--                        <span v-tr>Settings|Configuration</span>-->
<!--                    </h4>-->
<!--                </template>-->

            <tab-header v-model="newPage.name" :icon="getPublicIcon('file-bookmark')">
                <template #input>
                    <e-translation-input label="New Page Name|Nom de la nouvelle page" v-model="newPage.name" @change="onNameChanged">
                        <template #form-help>
                            <div class="form-help mt-2" v-tr>
                                The page name will be used for navigation links|
                                Le nom de la page sera utilisé pour les liens de navigation
                            </div>
                        </template>
                    </e-translation-input>
                </template>
            </tab-header>

            <hr class="my-4">
                <b-form-group :label="'Type|Type de page'|tr">
                    <b-input-group>
                        <b-input-group-text><i class="fas fa-page"/></b-input-group-text>
                        <b-select v-model="newPage.type">
                            <option value="blocks" v-tr>Regular Page|Page normale</option>
                            <option value="landing-page" v-tr>Landing Page|Page marketing (landing)</option>
                            <option value="cms-item-list" v-tr>CMS Items List|Liste d'éléments du CMS</option>
                            <option value="cms-item-view" v-tr>CMS Item Details|Page d'un élément du CMS</option>
                            <option value="landing-page" v-tr>Landing Page|Page marketing (landing)</option>
                            <option value="map" v-tr>Wayfinder|Wayfinding</option>
                            <!--                            <option value="search" v-tr>Search Page|Page de recherche</option>-->
                            <option value="universal-search" v-tr>Site Search|Rechercher dans le site</option>
                        </b-select>
                    </b-input-group>
                </b-form-group>
                <div class="form-help mb-3">
                    <span v-tr>You won't be able to change the type after saving|Vous ne pourrez plus changer le type après avoir enregistré</span>
                </div>

                <b-form-group v-if="newPage.type === 'search' || newPage.type === 'cms-item-list' || newPage.type === 'cms-item-view'">
                    <select-cms-item-type v-model="newPage.itemType" :item-types="cms.types" @input="onItemTypeChanged" append-to-body/>
                </b-form-group>

            <div v-if="isItemPage" class="form-help mt-2">Ex: https://{{ website.domain }}{{ tr(newPage.permalink) }}</div>

            <hr>

            <e-translation-input v-if="!isItemPage" label="Page URL|URL de la page" v-model="newPage.permalink" :readonly="!customizePermalink">
                <template #label-info>
                    <b-checkbox switch class="float-right" v-model="customizePermalink">
                        <span v-tr>Customize|Personnaliser</span>
                    </b-checkbox>
                </template>
                <template #form-help>
                    <div class="form-help mt-2">Ex: https://{{ website.domain }}/{{ tr(newPage.permalink) }}</div>
                    <template v-if="customizePermalink">
                        <div class="form-help mt-2" v-tr>No Space and No Special Characters|Aucun espace ni caractères spéciaux</div>
                    </template>
                </template>
                <template #append>
                    <b-btn variant="light" @click="updatePermalink">
                        <i class="fas fa-sync"/>
                    </b-btn>
                </template>
            </e-translation-input>

            <b-form-group :label="'Page Icon|Icône de la page'|tr">
                <input-glyph v-model="newPage.glyph"/>
            </b-form-group>

            <b-form-group v-if="newPage.type === `map`" label="Map ID">
                <b-input v-model="newPage.mapId"/>
            </b-form-group>

            <b-form-group v-if="newPage.type !== `cms-item-view`">
                <b-checkbox v-model="addToNavigation">
                    <span v-tr>Add to Navigation|Ajouter à la navigation</span>
                </b-checkbox>
                <div class="form-help mt-2" v-tr>This will add the page to the navigation menu|Cela ajoutera la page dans le menu de navigation</div>
            </b-form-group>
<!--            </b-card>-->
        </template>

        <template v-if="newPage" #footer="{close, refresh}">
            <b-btn variant="primary" @click="create()" :disabled="!newPage.name">
                <span v-tr>Create|Créer</span>
            </b-btn>
        </template>
    </e-iframe-page>
</template>

<script>
import InputGlyph from "@/components/editor/properties/inputs/input-glyph.vue";
import SelectCmsItemType from "@/components/editor/properties/inputs/select-cms-item-type.vue";
import CmsPage from "@/helpers/cms/CmsPage.js";
import TabHeader from "@/layout/tab-header.vue";
import NetworkV2 from "@/../vue-components/helpers/NetworkV2.js";
import ETranslationInput from "@/../vue-components/components/e-translation-input.vue";
import EIframePage from "@/../vue-components/components/e-iframe-page.vue";
import CmsHelper from "../../vue-components/helpers/CmsHelper.js";
import {pluralize} from "../../vue-components/helpers/vue-filters.js";

// iframe-page-creation #hot-reload-debug
export default {
    name: `iframe-page-creation`,
    components: {InputGlyph, SelectCmsItemType, ETranslationInput, TabHeader, EIframePage},
    data() {
        return {
            loading: false,
            newPage: null,
            customizePermalink: false,
            addToNavigation: true,
            duplicateId: null
        }
    },
    computed: {
        dealerIds() {
            return this.$store.state.dealers.map(dealer => {
                return dealer.dealerid;
            });
        },
        isItemPage() {
            return this.newPage.type === `cms-item-list` || this.newPage.type === `cms-item-view`;
        },
        itemType() {
            if (!this.newPage.itemType) {
                return null;
            } else {
                return this.cms.types.find(t => t.type === this.newPage.itemType);
            }
        }
    },
    mounted() {
        this.duplicateId = this.$route.query.duplicateId;
        if (this.duplicateId) {
            this.loading = true;
            NetworkV2.get(`/api/page/${this.duplicateId}`)
                .then(resp => {
                    const clone = structuredClone(resp.data);
                    delete clone.id;
                    this.newPage = clone;

                    this.newPage.name = {
                        en: `Copy of ${this.newPage.name.en}`,
                        fr: `Copie de ${this.newPage.name.fr}`
                    };
                    this.newPage.permalink = {
                        en: `${this.newPage.permalink.en}-copy`,
                        fr: `${this.newPage.permalink.fr}-copie`
                    };

                    this.loading = false;
                });
        } else {
            this.newPage = new CmsPage();
            this.newPage.dealerid = this.website.dealerid;
        }
    },
    methods: {
        onItemTypeChanged() {
            this.updatePermalink();
            this.newPage.name = clone(this.itemType.name);
            if (this.newPage.type === `cms-item-list`) {
                for (let lang in this.newPage.name) {
                    this.newPage.name[lang] = pluralize(this.newPage.name[lang]);
                }
            } else if (this.newPage.type === `cms-item-view`) {
                this.addToNavigation = false;
            }
            if (this.itemType.glyph) {
                this.newPage.glyph = this.itemType.glyph;
            }
        },
        onNameChanged() {
            if (!this.customizePermalink) {
                this.updatePermalink();
            }
        },
        updatePermalink() {
            if (this.newPage.type === `cms-item-list`) {
                this.newPage.permalink = {
                    default: this.itemType.listUrl
                }
            } else if (this.newPage.type === `cms-item-view`) {
                this.newPage.permalink = {
                    default: this.itemType.viewUrl
                }
            } else {
                this.newPage.permalink = {};
                for (let lang in this.newPage.name) {
                    this.newPage.permalink[lang] = CmsHelper.textToPermalink(this.newPage.name[lang]);
                }
            }
        },
        create() {
            if (!this.newPage.name) {
                this.showInvalidFields();
                return false;
            }
            this.showLoading();

            switch (this.newPage.type) {
                case `universal-search`:
                    this.newPage.type = `search`;
                    this.newPage.itemType = `universal-search`;
                    break;
            }

            NetworkV2.post(`/api/pages/create`, {
                websiteId: this.website.id,
                page: this.newPage,
                addToNavigation: this.addToNavigation
            })
                .then(resp => {
                    this.showSavedMessage(`Created|Créé`, `Page created successfully|Page créé avec succès`);

                    this.$refs.iframePage.created(resp.data);
                });
        }
    }
}
</script>

<style scoped>

</style>
